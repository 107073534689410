<template>
  <v-card class="mt-sm-8 mt-md-5 mt-7 bg-transparent">
    <div class="card-padding pb-0">
      <h4 class="text-h4 font-weight-bolder text-typo mb-2 d-flex justify-start align-center">
        <v-img src="@/assets/logo.png" class="me-2" style="max-width: 30px;"></v-img>
        <span>Reset password</span>
      </h4>
      <p class="mb-0 text-body">Enter the email address you used to register your account, and we will send you an email with a link to reset your password.</p>
    </div>
    <form @submit.prevent="resetPassword()" class="card-padding pb-4">
      <template v-if="!sent">
        <label class="text-xs text-typo font-weight-bolder ms-1">Email</label>
        <v-text-field ref="email" v-model="email" hide-details outlined background-color="rgba(255,255,255,.9)" color="rgba(0,0,0,.6)" light height="40" placeholder="Email"
                      class="input-style font-size-input border border-radius-md placeholder-lighter text-color-light mb-4 mt-2">
        </v-text-field>
      </template>

      <v-expand-transition>
        <v-alert v-if="errorMessage" class="alert alert-danger py-2" dark>
          <div class="d-flex align-center justify-lg-space-between">
            <div>
              <span class="text-sm me-1 text-white"><v-icon class="me-1">fas fa-ban</v-icon></span>
              <span class="text-white text-sm">{{errorMessage}}</span>
            </div>
          </div>
        </v-alert>
      </v-expand-transition>
      <v-expand-transition>
          <v-alert v-if="success" class="alert alert-success py-2" dark>
              <div class="d-flex align-center justify-lg-space-between">
                  <div>
                      <span class="text-sm me-1 text-white"><v-icon class="me-1">fas fa-check</v-icon></span>
                      <span class="text-white text-sm">{{success}}</span>
                  </div>
              </div>
          </v-alert>
      </v-expand-transition>


      <v-btn v-if="!sent" :disabled="pending || !email" elevation="0" :ripple="false" height="43" color="#5e72e4" small type="submit"
             class="font-weight-bold text-uppercase btn-primary bg-gradient-primary py-2 px-6 me-2 mt-4 mb-2 w-100">
        Reset password
      </v-btn>

      <div class="text-center">
        <p class="text-sm text-body mt-3 mb-0">
          Back to
          <router-link to="/sign-in" class="text-primary text-gradient text-decoration-none font-weight-bold">Sign in</router-link>
        </p>
      </div>
    </form>
  </v-card>
</template>
<script>

export default {
  name: 'reset-password',

  data() {
    return {
      email: null,
      errorMessage: null,
      pending: false,
      success: null
    }
  },

  mounted() {
    this.$nextTick(() => this.$refs.email.focus())
  },

  methods: {
    async resetPassword() {
      this.pending = true
      this.errorMessage = null

        try {
        await this.$store.state.api.request({url: `${process.env.VUE_APP_API_URL}/reset-password`, method: 'PATCH', data: {email: this.email}})
        this.success = 'Recovery link was sent. Check your email inbox.'
        this.sent = true
      } catch (e) {
        this.errorMessage = 'You set wrong credentials.'
      }
    }
  }
}
</script>

<style>
  .theme--light.v-btn.v-btn--disabled {
    color: #fff !important;
    opacity: .6;
  }
</style>
