
export const SUCCESS_COLOR = '#75cb0c'
export const DANGER_COLOR = '#cb0c3f'


export const ENVIRONMENT_COLOR = '#75cb0c'
export const ENVIRONMENT_GRADIENT = (ctx) => {
    const gradient = ctx.createLinearGradient(0, 230, 0, 50)
    gradient.addColorStop(1, 'rgba(82,203,12,0.2)')
    gradient.addColorStop(0.2, 'rgba(72,72,176,0.0)')
    gradient.addColorStop(0, 'rgba(203,12,159,0)')
    return gradient
}

export const SOCIAL_COLOR = '#cb0c9f'
export const SOCIAL_GRADIENT = (ctx) => {
    const gradient = ctx.createLinearGradient(0, 230, 0, 50)
    gradient.addColorStop(1, 'rgba(203,12,159,0.2)')
    gradient.addColorStop(0.2, 'rgba(72,72,176,0.0)')
    gradient.addColorStop(0, 'rgba(203,12,159,0)')
    return gradient
}

export const GOVERNANCE_COLOR = '#3A416F'
export const GOVERNANCE_GRADIENT = (ctx) => {
    const gradient = ctx.createLinearGradient(0, 230, 0, 50)
    gradient.addColorStop(1, 'rgba(20,23,39,0.2)')
    gradient.addColorStop(0.2, 'rgba(72,72,176,0.0)')
    gradient.addColorStop(0, 'rgba(20,23,39,0)')
    return gradient
}

export const MARKET_COLOR = '#e12727'
export const MARKET_GRADIENT = (ctx) => {
    const gradient = ctx.createLinearGradient(0, 230, 0, 50)
    gradient.addColorStop(1, 'rgba(225, 39, 39, 0.2)')
    gradient.addColorStop(0.2, 'rgba(240, 40, 40, 0.1)')
    gradient.addColorStop(0, 'rgba(95, 95, 95, 0)')
    return gradient
}

export const BLOOMBERG_COLOR = '#ffd30d'
export const BLOOMBERG_GRADIENT = (ctx) => {
    const gradient = ctx.createLinearGradient(0, 230, 0, 50)
    gradient.addColorStop(1, 'rgba(255, 211, 13, 0.2)')
    gradient.addColorStop(0.2, 'rgba(255, 233, 15, 0.1)')
    gradient.addColorStop(0, 'rgba(0, 0, 0, 0)')
    return gradient
}

export const getTooltipPercentFormatted = () => {
    return {
        callbacks: {
            label: function (context) {
                return `${context.dataset.label} ${context.formattedValue}%`
            }
        }
    }
}

export const getColorBySector = sector => {
    const sectors = {environment: ENVIRONMENT_COLOR, social: SOCIAL_COLOR, governance: GOVERNANCE_COLOR, market: MARKET_COLOR, bloomberg: BLOOMBERG_COLOR}
    return sectors[sector]
}

export const getChartGradientBySector = (sector, ctx) => {
    switch (sector) {
        case 'Environment':
        case 'environment':
            return ENVIRONMENT_GRADIENT(ctx)
        case 'Social':
        case 'social':
            return SOCIAL_GRADIENT(ctx)
        case 'Governance':
        case 'governance':
            return GOVERNANCE_GRADIENT(ctx)
        case 'Market':
        case 'market':
            return MARKET_GRADIENT(ctx)
        case 'Bloomberg':
        case 'bloomberg':
            return BLOOMBERG_GRADIENT(ctx)
        default:
            return null
    }
}
