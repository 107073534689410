import {icons} from '@/components/Icons/Icons'
import {getColorBySector} from '@/util/charts'

export class FundScan {
    constructor(i) {
        let esg = {e: 0, s: 0, g: 0}
        if (i.external_esg_ratings_json) {
            try {esg = JSON.parse(i.external_esg_ratings_json)} catch (e) {}
        }

        const props = {
            ...i,
            url_doc_preview: this.createUrlDocPreview(i.url_doc),
            avatar: require(`@/assets/img/logos/${i % 2 ? 'visa' : 'mastercard'}.png`),
            trends: [
                {name: 'Environment', color: getColorBySector('environment'), icon: icons.environment.icon, value: esg.e * 100},
                {name: 'Social', color: getColorBySector('social'), icon: icons.social.icon, value: esg.s * 100},
                {name: 'Governance', color: getColorBySector('governance'), icon: icons.governance.icon, value: esg.g * 100},
            ],
            companies_warnings: [],
            propsPending: true,
            propsFetched: false,
            warningsPending: true,
            warningsFetched: false,
        }

        Object.keys(props).map(i => this[i] = props[i])
    }

    createUrlDocPreview(url_doc) {
        if (!url_doc?.length) {
            return ''
        }

        const split = url_doc.split('/').reverse()
        if (split.length) {
            if (split[0] === '') {
                split.shift()
            }
        }
        const url_doc_preview = split?.length ? split[0] : null
        return url_doc_preview.length > 45 ? url_doc_preview.substring(0, 45) + '...' : url_doc_preview
    }

    setProps(props) {
        for (const key in props) {
            this[key] = props[key]
        }
        this.propsPending = false
        this.propsFetched = true
    }

    setWarnings(warnings) {
        this.companies_warnings = warnings
        this.warningsPending =  false
        this.warningsFetched = true
    }
}
