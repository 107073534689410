import Router from '../router'

export default function responseInterceptor(api) {
    return api.interceptors.response
        .use((response) => response, (error) => {
            if (error.response.status === 401) {
                localStorage.removeItem('BotXESGToken')
                return Router.push('/sign-in')
            }
            throw error
        })
}
