import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import DashboardPlugin from './plugins/dashboard-plugin'
import {createStore} from '@/store'

Vue.config.productionTip = false

Vue
    .use(Vuex)
    .use(DashboardPlugin)


new Vue({
    router,
    store: createStore(),
    vuetify,
    render: h => h(App)
})
    .$mount('#app')
