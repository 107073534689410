import Vue from 'vue'
import VueRouter from 'vue-router'

import DashboardLayout from '../views/Layout/DashboardLayout.vue'
import ESGLoginLayout from '@/views/Layout/SignInLayout'
import ResetPassword from '@/views/Pages/Auth/ResetPassword'

// const Landing = () => import(/* webpackChunkName: "auth" */ '@/views/Pages/Landing/Landing.vue')
const SignIn = () => import(/* webpackChunkName: "auth" */ '@/views/Pages/Auth/SignIn/SignIn.vue')
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '@/views/Pages/Dashboard/Dashboard.vue')
// const Portfolio = () => import(/* webpackChunkName: "company" */ '@/views/Pages/Portfolio/Portfolio.vue')
const PortfolioFunds = () => import(/* webpackChunkName: "company" */ '@/views/Pages/Portfolio/Funds/PortfolioFunds.vue')
const PortfolioCompanies = () => import(/* webpackChunkName: "company" */ '@/views/Pages/Portfolio/Companies/PortfolioCompanies.vue')
const NewCompany = () => import(/* webpackChunkName: "company" */ '@/views/Pages/Portfolio/NewCompany.vue')
const DetailCompany = () => import(/* webpackChunkName: "company" */ '@/views/Pages/Portfolio/DetailCompany.vue')
const DetailFund = () => import(/* webpackChunkName: "company" */ '@/views/Pages/Portfolio/DetailFund.vue')
const Feed = () => import(/* webpackChunkName: "feed" */ '@/views/Pages/Feed/Feed.vue')
const FundScans = () => import(/* webpackChunkName: "feed" */ '@/views/Pages/FundScans/FundScans.vue')

Vue.use(VueRouter)

const landing = {path: '/', redirect: '/sign-in'};

const auth = {
  path: '/sign-in',
  component: ESGLoginLayout,
  name: 'LoginLayout',
  children: [
    {
      path: '/sign-in',
      name: 'SignIn',
      component: SignIn,
    },
  {
      path: '/reset-password',
      name: 'ResetPassword',
      component: ResetPassword,
  },
  ],
};

const app = {
  path: '/app',
  component: DashboardLayout,
  name: 'ESG',
  children: [
    {
      path: '/app/dashboard',
      name: 'Dashboard',
      component: Dashboard,
      meta: {
        hideFooter: true,
        groupName: 'ESG',
      },
    },
    {
      path: '/app/new-company',
      name: 'NewCompany',
      component: NewCompany,
      meta: {
        hideFooter: true,
        groupName: 'ESG',
      },
    },
    {
      path: '/app/portfolio/company',
      name: 'PortfolioCompanies',
      component: PortfolioCompanies,
      meta: {
        hideFooter: true,
        groupName: 'ESG',
      },
    },
    {
      path: '/app/portfolio/company/:id',
      name: 'Company',
      component: DetailCompany,
      meta: {
        hideFooter: true,
        groupName: 'ESG',
      },
    },
    {
      path: '/app/portfolio/fund',
      name: 'PortfolioFunds',
      component: PortfolioFunds,
      meta: {
        hideFooter: true,
        groupName: 'ESG',
      },
    },
    {
      path: '/app/portfolio/fund/:id',
      name: 'Fund',
      component: DetailFund,
      meta: {
        hideFooter: true,
        groupName: 'ESG',
      },
    },
    {
      path: '/app/feed/plausibility-checks',
      name: 'Feed',
      component: Feed,
      meta: {
        hideFooter: true,
        groupName: 'ESG',
      },
    },
      {
          path: '/app/feed/fund-scans',
          name: 'Fund Scans',
          component: FundScans,
          meta: {
              hideFooter: true,
              groupName: 'ESG',
          },
      },
    {path: '/app', redirect: '/app/dashboard'},
  ]
}

const routes = [landing, auth, app]

const router = new VueRouter({routes})

export default router
