import {replace} from 'lodash'

export const icons = {
    environment: {
        title: 'leaf',
        icon: `<svg style="vertical-align: middle" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 21C5.5 16.5 7.5 13 12 11M8.99999 18C15.218 18 19.5 14.712 20 6V4H15.986C6.98599 4 3.99999 8 3.98599 13C3.98599 14 3.98599 16 5.98599 18H8.99999Z" stroke="#89D86E" stroke-width="2" stroke-linecap="currentColor" stroke-linejoin="round" /></svg>`,
    },
    social: {
        title: 'user',
        icon: `<svg style="vertical-align: middle" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.16797 18.849C6.41548 18.0252 6.92194 17.3032 7.61222 16.79C8.30249 16.2768 9.13982 15.9997 9.99997 16H14C14.8612 15.9997 15.6996 16.2774 16.3904 16.7918C17.0811 17.3062 17.5874 18.0298 17.834 18.855M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM15 10C15 11.6569 13.6569 13 12 13C10.3431 13 9 11.6569 9 10C9 8.34315 10.3431 7 12 7C13.6569 7 15 8.34315 15 10Z" stroke="#E83E8C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /></svg>`,
    },
    governance: {
        title: 'building',
        icon: `<svg style="vertical-align: middle" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M3 21H21M3 10H21M5 6L12 3L19 6M4 10V21M20 10V21M8 14V17M12 14V17M16 14V17" stroke="#0645F0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /> </svg>`
    },

    render(key, width) {
        return replace(this[key].icon, 'width="24"', `width="${width}"`)
    }
}



