
export default function authInterceptor(api) {
    return api.interceptors.request.use(async (config) => {
        const token = localStorage.getItem('BotXESGToken')
        if (token) {
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${token}`
            }
        }
        return config
    }, (error) => {
        return Promise.reject(error)
    })
}
