<template>
    <router-view></router-view>
</template>

<script>
export default {
    data() {
        return {}
    },

    created() {
        this.$store.commit('setApi')
    }
}

</script>

<style>
div.v-navigation-drawer {
    max-width: 90vw;
}
</style>
