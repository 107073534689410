import Vuex from 'vuex'
import axios from 'axios'
import authInterceptor from '@/api/auth.interceptor'
import responseInterceptor from '@/api/response.interceptor'
import {Company} from '@/entities/Company'
import {FundScan} from '@/entities/FundScan'

export const createStore = () => {
    return new Vuex.Store({
        state: {
            companies: localStorage.getItem('ESGAllCompanies') ? JSON.parse(localStorage.getItem('ESGAllCompanies')) : [],
            funds: localStorage.getItem('ESGAllFunds') ? JSON.parse(localStorage.getItem('ESGAllFunds')) : [],
            api: null,
            dataFrames: null,
            // companiesDataFrame: null
        },

        getters: {
            getDataFrame: (state) => dataFrameName => {
                return state.dataFrames.find(i => i.dataFrame.dataFrameName === dataFrameName)?.dataFrame || null
            },

            find: (state) => async (dataFrameName, page, perPage, sort = JSON.stringify({_id: 'asc'})) => {
                const {data} = await state.api.get(`/${dataFrameName}?page=${page}&perPage=${perPage}&sort=${encodeURIComponent(sort)}`)
                return data
            },

            findOne: (state) => async (dataFrameName, id) => {
                const {data} = await state.api.get(`/${dataFrameName}/${id}`)
                return data
            },

            create: (state) => async (dataFrameName, payload) => {
                return await state.api.post(`/${dataFrameName}`, payload)
            },

            update: (state) => async (dataFrameName, id, payload) => {
                return await state.api.get(`/${dataFrameName}/${id}`, payload)
            },

            delete: (state) => async (dataFrameName, id) => {
                return await state.api.delete(`/${dataFrameName}/${id}`)
            },

            request: (state) => async (url, method, body) => {
                return await state.api.request({url, method, data: body})
            }
        },

        mutations: {
            setApi(state) {
                state.api = axios.create({baseURL: process.env.VUE_APP_API_URL})
                authInterceptor(state.api)
                responseInterceptor(state.api)
            },

            setCompanies(state, companies) {
                state.companies = companies.map(i => new Company(i))
                localStorage.setItem('ESGAllCompanies', JSON.stringify(state.companies))
            },

            setFunds(state, funds) {
                state.funds = funds.map(i => new FundScan(i))
                localStorage.setItem('ESGAllFunds', JSON.stringify(state.companies))
            },
        }
    })
}
