import {icons} from '@/components/Icons/Icons'
import {getColorBySector} from '@/util/charts'

export class Company {
    constructor(i) {
        const props = {
            _id: i._id,
            company_name: i.company_name,
            logo_url: i.logo_url,
            avatar: require(`@/assets/img/logos/${i % 2 ? 'visa' : 'mastercard'}.png`),
            trends: [],
            trendsPending: true,
            countryCode: i.country_code?.toUpperCase() || null
        }

        Object.keys(props).map(i => this[i] = props[i])

        this.sfdr = i.sfdr || 'Article 8'
        this.fund = i.fund || ''
    }

    setTrends(bbg) {
        console.log(bbg)
        this.trends = [
            {name: 'Environment', color: getColorBySector('environment'), icon: icons.environment.icon, value: bbg?.environment || 'N/A'},
            {name: 'Social', color: getColorBySector('social'), icon: icons.social.icon, value: bbg?.social || 'N/A'},
            {name: 'Governance', color: getColorBySector('governance'), icon: icons.governance.icon, value: bbg?.governance || 'N/A'},
        ]
        this.trendsPending = false
    }
}
